import { navigate } from "gatsby";
import { Box, Button } from "grommet";
import { Checkmark, Close, Edit } from "grommet-icons";
import React from "react";
import { EventEmitter } from "../components/elements/event_emitter";
import { FormFieldInput } from "../components/elements/form_field";
import PrimaryButton from "../components/elements/primary_button";
import StandardText, { COLOR } from "../components/elements/standard_text";
import NavigationFrame from "../components/nav_frame";
import { changePassword, updateUser } from "../service/login_service";
import storageService, { saveUser } from "../service/storage_service";


class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            savable: false,
            user: undefined,
            changePassMode: false,
            passwordObject: {}
        }
    }

    componentDidMount = () => {
        const user = storageService.getUser();
        if (!user) {
            navigate('/login')
            return;
        }
        this.setState({ user });
    }

    saveUser = () => {
        this.setState({ loading: true });
        const userToUpdate = { ...this.state.user };
        delete userToUpdate.role;
        updateUser(userToUpdate)
            .then(res => {
                const _user = { ...storageService.getUser(), ...res.data };
                console.log(_user, res.data);
                saveUser(_user);
                EventEmitter.dispatch('showMessage', { message: 'Profile is updated successfully.' });
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
                const message = err.response.data?.error?.message;
                EventEmitter.dispatch('showMessage', { message, messageType: 'error' });
            })
    }

    onAccountChange = (field, value) => {
        const user = this.state.user;
        user[field] = value;
        this.setState({ user: { ...user }, savable: true });
    }

    onPasswordObjectChange = (field, value) => {
        const passwordObject = this.state.passwordObject;
        passwordObject[field] = value;
        this.setState({ passwordObject: { ...passwordObject } })
    }

    changePassword = () => {
        const passwordObject = this.state.passwordObject;
        if (!passwordObject.password) {
            EventEmitter.dispatch("showMessage", { message: 'You must fill the current password', messageType: 'error' });
            return;
        }
        if (!passwordObject.newPassword) {
            EventEmitter.dispatch("showMessage", { message: 'You must fill the new password', messageType: 'error' });
            return;
        }
        if (passwordObject.newPassword === passwordObject.password) {
            EventEmitter.dispatch("showMessage", { message: 'Your new password must be different than your current password', messageType: 'error' });
            return;
        }
        if (passwordObject.newPassword.length < 6) {
            EventEmitter.dispatch("showMessage", { message: 'The new password should have at least 6 characters.', messageType: 'error' });
            return;
        }
        if (passwordObject.newPassword !== passwordObject.repeatPassword) {
            EventEmitter.dispatch("showMessage", { message: 'The new password does not match.', messageType: 'error' });
            return;
        }
        const body = {
            password: passwordObject.newPassword,
            passwordConfirmation: passwordObject.repeatPassword,
            currentPassword: passwordObject.password,
        }

        this.setState({ loading: true });
        changePassword(body)
            .then(_ => {
                EventEmitter.dispatch('showMessage', { message: 'Your password is changed successfully.' });
                this.setState({ loading: false, changePassMode: false, passwordObject: {} });
            })
            .catch(err => {
                console.log(err.response.data);
                const message = err.response.data?.error?.message || 'Unexpected error.';
                EventEmitter.dispatch('showMessage', { message, messageType: 'error' });
                this.setState({ loading: false });
            })
    }

    render() {
        return (
            <NavigationFrame>
                <Box pad='small' style={{ height: '100vh', overflowY: 'auto' }} width='xxlarge' gap='small'>

                    <Box direction="row" align="center" width={'full'}
                        background='#F8F8F8'
                        round='small'
                        justify="between" pad={'small'}
                        margin={{ bottom: 'medium' }}
                    >
                        <Box pad={'xsmall'}>
                            <StandardText label={'Profile'} style={{ fontWeight: 'bold' }} size='large' />
                        </Box>
                        <Box align="center">
                            <PrimaryButton label="Save"
                                disabled={!this.state.savable}
                                onClick={this.saveUser}
                            />
                        </Box>
                    </Box>
                    <Box width={'large'} gap='small' pad={{ horizontal: 'small', bottom: "medium" }} style={{ minHeight: 'auto' }}>

                        <Box direction='row' gap='small' style={{ minHeight: 'auto' }}>
                            <FormFieldInput
                                label={'First name'}
                                value={this.state.user?.firstname}
                                onChange={event => this.onAccountChange('firstname', event.target.value)}
                            />
                            <FormFieldInput
                                label={'Last name'}
                                value={this.state.user?.lastname}
                                onChange={event => this.onAccountChange('lastname', event.target.value)}
                            />
                        </Box>
                        <Box direction='row' gap='small' style={{ minHeight: 'auto' }}>
                            <FormFieldInput
                                label={'Email/Username'}
                                type="email"
                                value={this.state.user?.email}
                            />
                            <FormFieldInput
                                label={'Phone'}
                                type='phone'
                                value={this.state.user?.phone}
                                onChange={event => this.onAccountChange('phone', event.target.value)}
                            />
                        </Box>
                        <Box pad={'xsmall'} direction='row' gap="medium" align="center">
                            <StandardText label={'Change password'} style={{ fontWeight: 'bold' }} size='large' />
                            <Button icon={<Edit size="small" />} color='brand' plain={false}
                                onClick={() => this.setState({ changePassMode: !this.state.changePassMode })}
                            />
                        </Box>
                        {this.state.changePassMode ?
                            <Box width={'full'} gap='small'>
                                <Box gap='small' direction="row" style={{ minHeight: 'auto' }}>
                                    <FormFieldInput
                                        label={'Current password'}
                                        type="password"
                                        value={this.state.passwordObject.password}
                                        onChange={event => this.onPasswordObjectChange('password', event.target.value)}
                                    />
                                </Box>
                                <Box gap='small' direction="row" style={{ minHeight: 'auto' }}>
                                    <FormFieldInput
                                        label={'New password'}
                                        type="password"
                                        value={this.state.passwordObject.newPassword}
                                        onChange={event => this.onPasswordObjectChange('newPassword', event.target.value)}
                                    />
                                </Box>
                                <Box gap='small' direction="row" style={{ minHeight: 'auto' }}>
                                    <FormFieldInput
                                        label={'Repeat new password'}
                                        type="password"
                                        value={this.state.passwordObject.repeatPassword}
                                        onChange={event => this.onPasswordObjectChange('repeatPassword', event.target.value)}
                                    />
                                </Box>
                                <Box direction="row" gap="small" align="center" justify="center">
                                    <Button icon={<Close size='small' color={COLOR.error} />}
                                        plain={false}
                                        onClick={() => this.setState({ changePassMode: false })}
                                    />
                                    <Button icon={<Checkmark color="brand" size='small' />}
                                        plain={false}
                                        onClick={this.changePassword}
                                    />
                                </Box>
                            </Box> : null}
                    </Box>
                </Box>

            </NavigationFrame>
        )
    }
}

export default Profile;